import { message } from "antd";
import moment from "moment";
import numeral from "numeral";
import naira_img from "../assets/icons-png/nigeria.png";
import dollar_img from "../assets/icons-png/USD.png";

const ElectonicMoney = "Electronic Money Transfer Levy";

export const inputAlphabetRule = {
  pattern: /^[a-zA-Z\s-]+$/,
  message: "Only alphabets are allowed",
};

export const inputAddressRule = {
  pattern: /^[a-zA-Z0-9\s.,&-]+$/,
  message: "No special characters allowed",
};

export const last24Hours = {
  startTime: moment().startOf("day").format("YYYY-MM-DD"),
  endTime: moment().endOf("day").format("YYYY-MM-DD"),
};

export const lastSevenDays = {
  startTime: moment().subtract(6, "days").startOf("day").format("YYYY-MM-DD"),
  endTime: moment().endOf("day").format("YYYY-MM-DD"),
};

export const initalControlState = {
  message: "",
  isDowntime: false,
  isOpen: false,
};

export const defaultAccount = {
  accountNumber: null,
  code: null,
  url: null,
  name: null,
};

export const WalletCurrency = {
  NAIRA: "NAIRA",
  USD: "USD",
};

export const SettlementWalletType = {
  USDINTLSETTLEMENT: "usdIntlSettlement",
  NAIRAINTLSETTLEMENT: "nairaIntlSettlement",
};

export const ProductTransactionType = {
  AIRTIME: "AIRTIME",
  BETTING: "BETTING",
  CABLE_TV: "CABLE_TV",
  DATA: "DATA",
  PHCN: "PHCN",
  TRANSFER: "TRANSFER",
  PAYOUT: "PAYOUT",
};

export const CategoryType = {
  TRANSFER: "Transfer",
  WALLET_TOP_UP: "Wallet Top Up",
  P2P: "P2P",
  DATA: "Data",
  AIRTIME: "Airtime",
  CABLE_TV: "Cable TV (DSTV, GOTV or Startimes)",
  PHCN: "Electricity (PHCN)",
  WITHDRAWAL: "POS Withdrawal",
  USSD_WITHDRAWAL: "POS Withdrawal",
  BETTING: "Betting",
};

export const UserKycStatus = {
  APPROVED: "APPROVE",
  DECLINED: "DECLINE",
  PENDING: "PENDING",
};

export const AccountTypeStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  NOT_APPLICABLE: "NOT_APPLICABLE",
};

export const AccountType = {
  INDIVIDUAL: "INDIVIDUAL",
  BUSINESS: "BUSINESS",
};

export const TransactionType = {
  AIRTIME: "AIRTIME",
  WALLET_TOP_UP: "WALLET_TOP_UP",
  WITHDRAWAL: "WITHDRAWAL",
  CABLE_TV: "CABLE_TV",
  P2P: "P2P",
  PHCN: "PHCN",
  USSD_WITHDRAWAL: "USSD_WITHDRAWAL",
  DATA: "DATA",
  TRANSFER: "TRANSFER",
  DISBURSEMENT: "DISBURSEMENT",
  COMMISSION: "COMMISSION",
  SETTLEMENT: "SETTLEMENT",
  REVENUE: "REVENUE",
  PAYOUT: "PAYOUT",
  PURCHASE: "PURCHASE",
  LOAN: "LOAN",
  BETTING: "BETTING",
  LOAN_ALL: "LOAN_CREDIT,LOAN_REPAYMENT",
  NAIRA_INTL_WITHDRAWAL: "NAIRA_INTL_WITHDRAWAL",
  USD_INTL_WITHDRAWAL: "USD_INTL_WITHDRAWAL",
  STAMP_DUTY: "STAMP_DUTY",
};

export const ClientTransactionType = {
  "Stamp Duty": ElectonicMoney,
  [TransactionType.STAMP_DUTY]: ElectonicMoney,
  "Wallet Top-Up": "POS Transfer",
};

export const TransactionTypeMappings = {
  "POS Withdrawal": "POS Purchase",
  Withdrawal: "POS Purchase",
  "NAIRA Intl Withdrawal": "Naira Intl. Purchase",
  "USD Intl Withdrawal": "USD Intl. Purchase",
  [TransactionType.STAMP_DUTY]: ElectonicMoney,
  "Stamp Duty": ElectonicMoney,
};

export const walletCurrencyOption = [
  {
    value: "",
    icon: naira_img,
    label: "Naira (₦)",
  },
  {
    value: SettlementWalletType.NAIRAINTLSETTLEMENT,
    icon: naira_img,
    label: "Intl. Wallet (₦)",
  },

  {
    value: SettlementWalletType.USDINTLSETTLEMENT,
    icon: dollar_img,
    label: "Intl. Wallet ($)",
  },
];

export const formatPhoneNumber = (values) => {
  if (values.slice(0, 1) === "0") return values;

  return `0${values}`;
};

export const FormatMoneyString = (value, useDecimals) => {
  let currency = process.env.REACT_APP_COUNTRY_CURRENCY
    ? process.env.REACT_APP_COUNTRY_CURRENCY + " "
    : "";

  return currency + value;
};

export const mapTransactionType = (transactionTypes, params) => {
  if (params.category.includes("Issues")) return null;
  if (transactionTypes.length > 0) {
    const newType = transactionTypes.find((x) =>
      params.category.toUpperCase().replace(/\s/g, "_").includes(x.type)
    );
    if (newType) {
      return newType.type;
    }
  }
  if (params.category.includes("Withdrawal")) return "WITHDRAWAL";
  if (params.category.includes("Cable TV")) return "CABLE_TV";
  if (params.category.includes("PHCN")) return "PHCN";

  return params.category.toUpperCase().replace(/\s/g, "_");
};

export const changeFirstLetterToUpperCase = (value) =>
  value ? value.charAt(0).toUpperCase() + value.slice(1) : null;

export const defaultDate = {
  startTime: moment().startOf("month").format("YYYY-MM-DD"),
  endTime: moment().endOf("month").format("YYYY-MM-DD"),
};

export const FormatNumber = (value, useDecimals) => {
  if (!value) return 0;

  if (typeof value === "string") value = Number(value);

  return numeral(value).format(useDecimals ? "0,0,0.00" : "0,0,0");
};

export const FormatMoney = (value, useDecimals, currencyType) => {
  let currency =
    currencyType === WalletCurrency.USD
      ? "$"
      : process.env.REACT_APP_COUNTRY_CURRENCY;

  if (!value) return `${currency} 0`;

  if (typeof value === "string") value = Number(value);

  return `${currency} ${numeral(value).format(
    useDecimals ? "0,0,0.00" : "0,0,0"
  )}`;
};

export const DateFormat = (dateTime, format = "DD-MM-YYYY, hh:mma") => {
  if (!dateTime) {
    return "";
  }

  return moment(dateTime).format(format);
};

export const HumanFriendlyDate = (dateTime) => {
  if (!dateTime) {
    return "";
  }
  const _dateTime = moment(dateTime);
  return `${_dateTime.format("Do MMM, YYYY")}`;
};

export const removePlusSignPhoneNumber = (number) => {
  const removePlusSign = number.replace("+234", "0");
  const formattedPhone = removePlusSign.trim().replace(/ /g, "");

  return formattedPhone;
};

export const isEmptyObject = (obj) => {
  return Object.entries(obj).length === 0;
};

export const truncateCategory = (category) => {
  if (category?.includes("Cable")) {
    return "Cable TV";
  }
  return category;
};

export const handleTransactionType = (type) => {
  switch (type.toLowerCase()) {
    case "cable_tv":
      return CategoryType.CABLE_TV;
    case "phcn":
      return CategoryType.PHCN;
    case "data":
      return CategoryType.DATA;
    case "transfer":
      return CategoryType.TRANSFER;
    case "airtime":
      return CategoryType.AIRTIME;
    case "p2p":
      return CategoryType.P2P;
    case "betting":
      return CategoryType.BETTING;
    default:
      return type;
  }
};

export const validateSingleInput = (values, number) => {
  const inputValues = Object.values(values);
  if (
    inputValues.includes(undefined) ||
    inputValues.includes("") ||
    inputValues.length !== number
  ) {
    return {
      isValid: false,
    };
  }

  const value = Object.keys(values).reduce((value, item) => {
    return (value += values[item]);
  }, "");
  return {
    isValid: true,
    value,
  };
};

export const removeEmptyNullValue = (value) => {
  for (const key in value) {
    if (
      value[key] === null ||
      value[key] === undefined ||
      value[key] === "" ||
      value[key] === "Invalid date"
    ) {
      delete value[key];
    }
  }
  return value;
};

export const checkRCPrefix = (str) => {
  if (!str.startsWith("RC")) {
    return "RC" + str;
  } else {
    return str;
  }
};

export const checkTransactionStatus = (status) => {
  const statusInLowerCase = status?.toLowerCase();
  const isTransactionSuccessful = ![
    "failed",
    "payment flagged",
    "declined",
  ].includes(statusInLowerCase);

  const mapTitle = {
    "payment flagged": "Transaction has been flagged",
    declined: "Transaction Declined",
  };

  return {
    isTransactionSuccessful,
    statusTitle: mapTitle[statusInLowerCase] || "Transaction not successful",
  };
};

export const getStatusStyles = (status) => {
  const lowerStatus = status?.toLowerCase() || "";

  if (
    [
      "active",
      "resolved",
      "successful",
      "opened",
      "granted",
      "complete",
      "running",
    ].includes(lowerStatus)
  ) {
    return { color: "#00BE7C", backgroundColor: "#DEFFF4" };
  }

  if (
    [
      "failed",
      "payment failed",
      "payment flagged",
      "unresolved",
      "unassigned",
      "rejected",
      "inactive",
    ].includes(lowerStatus)
  ) {
    return { color: "#E2164B", backgroundColor: "#FDEAEF" };
  }

  if (["pending", "in_progress"].includes(lowerStatus)) {
    return { color: "#E8A24E", backgroundColor: "#FDF3E0" };
  }

  if (["refunded", "closed"].includes(lowerStatus)) {
    return { color: "#9B51E0", backgroundColor: "#9b51e01a" };
  }

  return { color: "#08979c", backgroundColor: "#e6fffb" };
};

export const handleDateOfBirth = async (_, value) => {
  const age = moment().diff(value, "years");
  if (age >= 18) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("You must be at least 18 years of old"));
};

export const handleBvnAndNinValidation = async (input, value) => {
  if (!value && value === "") {
    return Promise.reject(
      new Error(`${input.field.toUpperCase()} is required`)
    );
  }

  return Promise.resolve();
};

export const handleBalanceCheck = async (balance, amount) => {
  const reg = /^-?\d*(\.\d*)?$/;

  if (Number(amount) > balance) {
    return Promise.reject(new Error("You do not have sufficient balance"));
  }
  if (
    (amount !== "" && Number(amount) <= 0) ||
    !reg.test(amount) ||
    amount.includes("-")
  ) {
    return Promise.reject(new Error("Enter a valid amount"));
  }
  return Promise.resolve();
};

export const validateNumber = (_, inputValue) => {
  const reg = /^-?\d*(\.\d*)?$/;
  if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
    return Promise.resolve(true);
  }
  return Promise.reject(new Error("Number is Invalid"));
};

export function checkUsernameCharacter(str) {
  const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{7,}$/;
  if (regex.test(str)) {
    return Promise.resolve("success");
  } else {
    return Promise.reject({
      message:
        "Username must consist of at least 7 characters or alphanumeric but cannot consist of only numbers and space.",
    });
  }
}

export function getSubDomain() {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  return parts.length > 0 ? parts[0] : null;
}

export function getISODate(date) {
  if (typeof date === "string" && date?.length === 8) {
    const year = parseInt(date.substring(0, 4), 10);
    const month = parseInt(date.substring(4, 6), 10) - 1;
    const day = parseInt(date.substring(6, 8), 10);
    const newDate = new Date(Date.UTC(year, month, day));
    return newDate.toISOString();
  }
  const givenDate = new Date(date);

  givenDate.setUTCHours(0, 0, 0, 0);

  return givenDate.toISOString();
}

export const handleLoginResponse = (responseData, navigate) => {
  const bvnVerification =
    !responseData?.bvnVerified &&
    !responseData?.commercialBankMerchantEnabled &&
    !responseData?.onboardedViaAccountInfo &&
    !responseData?.ptspCommercialBankMerchantEnabled;

  /*if (
    responseData.kycStatus !== UserKycStatus.APPROVED &&
    responseData?.onboardedViaAccountInfo
  ) {
    const { title, message } =
      responseData.kycStatus === UserKycStatus.PENDING
        ? {
            title: "Account Pending Approval.",
            message: "We need to review your KYC. Kindly wait for a Approval",
          }
        : {
            title: "KYC Declined",
            message: `Your kyc has been declined by the banker due to the ${responseData?.kycStatusMessage}`,
          };
    navigate("/kyc", {
      state: {
        title,
        message,
      },
    });
    return;
  }*/

  if (bvnVerification) {
    return navigate("/bvn");
  }
  navigate("/home");
};

export async function copyText(text) {
  try {
    await navigator.clipboard.writeText(text);
    message.info({ content: "copied", duration: 3 });
  } catch {
    message.error({ content: "unable to copy", duration: 3 });
  }
}

export function canAddAccount(accounts, user) {
  if (user?.onboardedViaAccountInfo) {
    return false;
  }

  if (
    user?.commercialBankMerchantEnabled ||
    user?.ptspCommercialBankMerchantEnabled ||
    user?.unitSettlementAccountMerchantEnabled
  ) {
    return accounts?.length < 1;
  }

  return accounts?.length < 3;
}
